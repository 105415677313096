<template>
  <v-expansion-panels hover tile focusable :value="tab">
    <v-expansion-panel>
      <v-expansion-panel-header
        :id="id + '_header'"
        class="stepHeadColor subtitle-2"
        color="white--black"
        >{{ title }} <HelpButton  :id="id + '_header'" /></v-expansion-panel-header
      >
      <v-expansion-panel-content :id="id + '_content'">
        <slot></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import HelpButton from "../components/basic/HelpButton.vue";

export default {
  components: {HelpButton},
  computed: {
    tab() {
      let tab = null;
      if (this.expanded) {
        tab = 0;
      }
      return tab;
    },
  },
  props: { title: {}, expanded: { type: Boolean, default: false }, id: {} },
};
</script>
<style scoped>
.v-item-group {
  position: unset;
}
</style>
